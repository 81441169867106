/* Add this to your CSS file or use styled-components */

.upgrade-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow-y: hidden;
}

.upgrade-content {
  width: 100%;
  height: 100%;
  text-align: center;
  margin:auto;
  padding: 20px;
  background: linear-gradient(to bottom, #000000, #FF2540);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  color: #ffffff;
}

.upgrade-heading {
  font-size: 24px;
  margin-bottom: 10px;
}

.upgrade-description {
  font-size: 16px;
  margin-bottom: 20px;
}

.upgrade-btn,
.sign-out-btn {
  background-color: #ffffff;
  color: #000000;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.upgrade-btn:hover,
.sign-out-btn:hover {
  background-color: #f2f2f2;
}
