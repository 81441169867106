.search-url-container {
  margin-bottom: 2%;
  .dashboard-head {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .search-form {
    display: flex;
    gap: 10px;

    input[type="search"] {
      padding: 8px;
      font-size: 14px;
      border: 1px solid #ccc;
      border-radius: 4px;
      outline: none;
    }

    .submit-button,
    .reset-button {
      padding: 10px;
      font-size: 14px;
      background-color: #fff;
      color: #000;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #ddd;
      }
    }
  }

  .result-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;

    .row{
      padding: 5%;
    }

    th,
    td {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: left;
    }

    th {
      background-color: #f2f2f2;
    }

    .category-header {
      font-weight: bold;
      font-size: 1.2rem;
      background-color: #420b07;
    }

    .technology-name {
      font-weight: bold;
    }

    .technology-description {
      color: #fff;
    }
  }
}

.file-upload-form {
  .submit-button {
      padding: 10px;
      margin-left: 5px;
      font-size: 14px;
      background-color: #fff;
      color: #000;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #ddd;
      }
    }
  margin-top: 20px;

  label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
  }

  input[type="file"]::file-selector-button {
    border-radius: 4px;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
  }

  input[type="file"]::file-selector-button:hover {
    background-color: #ddd;
  }
  
  input[type="file"]::file-selector-button:active {
    background-color: #e5e7eb;
  }

  input[type="submit"] {
    padding: 10px;
    font-size: 14px;
    background-color: #fff;
    color: #000;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #ddd;
    }
  }

  .sign-out-btn {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 8px 16px;
    background-color: #f44336;
    color: #fff;
    border: none;
    cursor: pointer;
  }
}